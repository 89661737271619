html {
  scroll-behavior: smooth;
}
.swiper-wrapper {
  margin: 15px 0 20px 16px;
}
@media (min-width: 600px) {
  .swiper-wrapper {
    margin: 30px 0 40px 24px;
  }
}
.swiper-slide {
  width: auto !important;
}
.SnackbarItem-contentRoot {
  opacity: 1 !important;
}
a:-webkit-any-link {
  text-decoration: none;
}
